import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const RecibosService = {
	Listar: (ruta) => {
		let params = {ruta:ruta || null};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recibos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarHistorial: (ruta) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recibos_Descargas', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarComprobantesNomina: (ruta) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recibos_Descargas_Comprobantes_Nomina', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarFacturacion: (ruta) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Download_CFDI_Historial', params, ).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarAcuses: (ruta) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recibos_Descargas_Lineas_Captura', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DescargarZip: (ruta) => {
		let params = {ruta:ruta || null};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recibos_Descargar_Zip', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_sexo: form.id_cat_sexo,
			sexo: form.sexo,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Recibos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_sexo) => {
		let params = {
			id_cat_sexo: id_cat_sexo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Recibos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
